import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import HazelMDSlider from '../components/HazelMDSlider';

class Home extends Component{
    render(){
        return(
            <div>
              <NavBar/>
              
              <div style={{backgroundColor:'black'}}>
              <div className="info-container" style={{backgroundColor:'#e7b82c'}}>
                <div className="info-section">
                <HazelMDSlider/>
                <hr/>
                <h1 style={{color:'black', fontSize:'40px', textAlign:'center'}}>The Areas in Which We Provide Services</h1>
                <hr/>
                  <h1 id='food' style={{textAlign:'center'}}>FOOD</h1>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>We offer a healthy dining experience with products derived from high nutritional value and natural sources.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our foods, produced under globally compliant hygiene conditions, encompass a range of fresh and flavorful options.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Emphasizing innovation and quality, our food collection provides diverse gastronomic experiences.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Prioritizing eco-friendly methods in our food production processes, we support sustainability.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>By offering various alternatives tailored to nutritional needs, we contribute to our customers' healthy lifestyles.</li>
                    <br/>
                  </ul>
                  </div>
              </div>
              <div className="info-section">
                <div className='container'>
                    <hr/>
                  <h1 id='automotive' style={{textAlign:'center'}}>AUTOMOTIVE</h1>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Facilitating easy and effective vehicle maintenance, we provide a wide range of automotive parts.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our automotive parts, made from high-quality materials, ensure reliability in durability and performance.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Addressing specific needs of vehicles, we offer various parts to meet our customers' individual requirements.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>By staying updated on innovative technologies and current designs, we present customers with the latest automotive trends.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our expert team in automotive parts prioritizes customer satisfaction, delivering quality service.</li>
                    <hr/>
                    <br/>
                  </ul>
                  </div>
              </div>
              <div className="info-section">
                  <h1 id='cetrade' style={{textAlign:'center'}}>CUSTOMS & ENTREPOT TRADE</h1>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our specialized team in customs and warehouse processes provides customers with fast and seamless logistics solutions.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Managing complexities that may arise in international trade, we create a reliable and efficient trading environment.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Closely monitoring changes in customs processes, we offer customers suitable and up-to-date solutions.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our warehouse services provide flexible and customized options for storage and distribution.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Diligently working to meet customers' customs and warehouse needs, we actively support their trades.</li>
                    <br/>
                  </ul>
                  </div>
              </div>
              <div className="info-section">
                <div className='container'>
                    <hr/>
                  <h1 id='textile' style={{textAlign:'center'}}>TEXTILE</h1>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Emerging as a brand that blends fashion and quality, we offer a variety of textile products.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Using high-quality fabrics and materials, we provide customers with durable and stylish textile products.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Keeping up with the latest fashion trends, we regularly update our collections, offering customers a variety of options.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Supporting sustainable fashion practices through eco-friendly production processes, we deliver environmentally conscious products.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>With advanced quality control methods, we assure our customers of the highest quality and satisfaction.</li>
                    <br/>
                  </ul>
                  </div>
                  
                  
                  
                  
                  <div className="info-section">
                <div className='container'>
                    <hr/>
                  <h1 id='aboutUs' style={{textAlign:'center'}}>ABOUT US</h1><br/>

                  <h1 style={{textAlign:'left', textDecoration:'underline'}}>Who Are We?</h1>
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  <ul>
                    
                  <li style={{fontSize:'30px'}}>Our company is an organization specializing in a wide range of expertise, covering the fields of food, automotive, customs and warehouse operations, as well as textile materials. With our industry-specific experience and diversity, we aspire to stand out as a prominent brand by providing versatile solutions to our customers.</li><br/>
                  <h1 style={{textAlign:'left', textDecoration:'underline'}}>Our Mission</h1>
                  
                  <h2 style={{textAlign:'center'}}>•••••</h2>

                  <li style={{fontSize:'30px'}}>Adopting customer satisfaction as a fundamental principle, we aim to elevate quality standards in each sector we operate in. Our goal is to create value for our customers in the fields of food, automotive, customs and warehouse operations, as well as textile materials, and to establish sustainable business relationships.</li><br/>
                  <h1 style={{textAlign:'left', textDecoration:'underline'}}>Our Vision</h1>
                  
                  <h2 style={{textAlign:'center'}}>•••••</h2>
                  
                  <li style={{fontSize:'30px'}}>With our foresight in anticipating industry changes, we aim to progress based on innovation and sustainability principles. Striving for leadership in the global market, we aspire to become a pioneer in the fields of food, automotive, customs and warehouse operations, as well as textile materials, achieving sustainable success.</li>
                    <br/>
                  </ul>
                  </div>
                  </div>
              </div>
              </div>
              <Footer/>
            </div>
            </div>
        )
    }
}
export default Home;