import React, { Component } from 'react';

class HazelMDSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        'welcome.gif',
        '1.jpg',
        '19.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
        '8.jpg',
        '9.jpg',
        '10.jpg',
        '11.jpg',
        '12.jpg',
        '13.jpg',
        '14.jpg',
        '15.jpg',
        '16.jpg',
        '16.jpg',
        '17.jpg',
        '18.jpg'
      ],
      info: [
      ],
      currentIndex: 0,
      windowHeight:window.innerHeight,
    };
  }
  componentDidMount() {
    this.startAutoSlider();
    window.addEventListener('resize', this.handleWindowResize);
  }
  componentWillUnmount() {
    this.stopAutoSlider();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize=()=>{
    this.setState({
      windowHeight:window.innerHeight,
    });
  };
  startAutoSlider = () => {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({
        currentIndex:
          prevState.currentIndex === prevState.images.length - 1
            ? 0
            : prevState.currentIndex + 1,
      }));
    }, 4590);
  };
  stopAutoSlider = () => {
    clearInterval(this.intervalId);
  };
  handlePrev = () => {
    this.stopAutoSlider();
    this.setState((prevState) => ({
      currentIndex:
        prevState.currentIndex === 0
          ? prevState.images.length - 1
          : prevState.currentIndex - 1,
    }));
  };
  handleNext = () => {
    this.stopAutoSlider();
    this.setState((prevState) => ({
      currentIndex:
        prevState.currentIndex === prevState.images.length - 1
          ? 0
          : prevState.currentIndex + 1,
    }));
  };
  render() {
    const { images, currentIndex, windowHeight } = this.state;
    const imageUrl = images[currentIndex];
    return (
      <div style={{ position: 'relative', display:'flex', flexWrap:'nowrap', justifyContent:'center', alignItems:'center', maxHeight:{windowHeight}, padding:20 }}>
        <img
          src={imageUrl}
          alt="Photo"
          style={{ width: '90%',  maxHeight:windowHeight, objectFit: 'cover' }}
        />
        
      </div>
    );
  }
}
export default HazelMDSlider;