import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  render() {
    return (
      <div>
        <div className="footer-area" style={{backgroundColor:'black'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--100">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-10">
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo">
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 offset-xl-1 col-lg-12 col-md-6">
                      <div className="footer-widget">
                        <h4 className="footer-widget__title" style={{color:'white', textAlign:'center', fontSize:'38px'}}><u>Ways of Communication</u></h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <p style={{color:'#e7b82c', fontSize:'30px', textAlign:'center'}}>•• Reach Out Via e-Mail ••</p>
                            <p style={{textAlign:'center'}}><a href="mailto:info@hazelmd.net"><p style={{fontSize:'30px'}}>info@hazelmd.net</p></a></p>
                          </li>
                          <br/>
                          <li>
                            <p style={{color:'#e7b82c', fontSize:'29px', textAlign:'center'}}>•• Contact Through WhatsApp ••</p>
                            <p style={{textAlign:'center'}}><a href="https://wa.me/905316215704"><p style={{fontSize:'30px'}}>+(90) 531 621 57 04</p></a></p>
                          </li>
                          <br/>
                          <li>
                            <p style={{color:'#e7b82c', fontSize:'30px', textAlign:'center'}}>•• Our Business Address ••</p>
                            <p style={{textAlign:'center'}}><a href='#'><p style={{fontSize:'20px'}}>Mahmutbey Mah. Taşocağı Yolu<br/> Cad. Balance Güneşli No: 19/1A<br /> Bağcılar-İSTANBUL</p></a></p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              <p className="copyright-text">
              <marquee>&copy; {new Date().getFullYear()} Hazel MD Food Industry & Trade Limited Company | All Rights Reserved |</marquee>
              </p>
            </div>
          </div>
        </div>
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
      </div>
    );
  }
}
export default Footer;