import React, { Component } from 'react';
import MobileMenu from './MobileMenu';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll(){
    const threshold=100;
    const scrollY=window.scrollY;

    if(scrollY>threshold){
      this.setState({isSticky:false});
    }
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div>
        <div className="header-area header-sticky header-sticky--default" style={{backgroundColor:'#000000'}}>
          <div className="header-area__desktop header-area__desktop--default">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="header-info-wrapper align-items-center">
                    <div className="logo" style={{maxWidth:'100%'}}>
                      <img src="logo.png" alt="Logo" style={{ width: '40%', height:'6rem', objectFit:'cover' }} />
                    </div>
                    <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.activeMobileMenu}>
                      <i />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-navigation-area default-bg" style={{ backgroundColor: 'black' }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav style={{display:'flex', flexWrap:'nowrap', justifyContent:'flex-end'}}>
                          <ul>
                            <li style={{ color: 'white', fontSize: '40px' }}>|</li>
                            <li>
                              <a href="#food" style={{ fontSize: '20px', color: '#e7b82c' }}>
                                Food
                              </a>
                            </li>
                            <li style={{ color: 'white', fontSize: '40px' }}>|</li>
                            <li>
                              <a href="#automotive" style={{ fontSize: '20px', color: '#e7b82c' }}>
                                Automotive
                              </a>
                            </li>
                            <li style={{ color: 'white', fontSize: '40px' }}>|</li>
                            <li>
                              <a href="#cetrade" style={{ fontSize: '20px', color: '#e7b82c' }}>
                                Customs & Entrepot Trade
                              </a>
                            </li>
                            <li style={{ color: 'white', fontSize: '40px' }}>|</li>
                            <li>
                              <a href="#textile" style={{ fontSize: '20px', color: '#e7b82c' }}>
                                Textile
                              </a>
                            </li>
                            <li style={{ color: 'white', fontSize: '40px' }}>|</li>
                            <li>
                              <a href="#aboutUs" style={{ fontSize: '20px', color: '#e7b82c' }}>
                                About Us
                              </a>
                            </li>
                            <li style={{ color: 'white', fontSize: '40px' }}>|</li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
