import React, {  Suspense} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";

const App=()=>{
  return(
    <Router >
      <Suspense fallback={<div/>}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Home" element={<Home/>}/>
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </Suspense>
    </Router>
  );
};
export default App;