import React, {Component} from 'react';
class MobileMenu extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }
    render(){
        return(
            
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </a>
                    <div className="offcanvas-wrapper" style={{backgroundColor:'#000000'}}>
                        <div className="offcanvas-inner-content">
                        <div className="menu-logo" style={{color:'#e7b82c', fontSize:'30px'}}>
                            <strong>Hazel MD Mobile</strong>
                        </div>
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                                <ul>
                                    <li><a href='#food' style={{fontSize:'20px', color:'#e7b82c'}}><br/><br/>Food</a></li><br/>
                                    <li><a href='#automotive' style={{fontSize:'20px', color:'#e7b82c'}}>Automotive</a></li><br/>
                                    <li><a href='#cetrade' style={{fontSize:'20px', color:'#e7b82c'}}>C.E. Trade</a></li><br/>
                                    <li><a href='#textile' style={{fontSize:'20px', color:'#e7b82c'}}>Textile</a></li><br/>
                                    <li><a href='#aboutUs' style={{fontSize:'20px', color:'#e7b82c'}}>About Us</a></li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
        )
    }
}
export default MobileMenu;